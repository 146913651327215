.header-5-container
  position: relative
  min-height: 200px
  display: flex
  justify-content: space-between
  .header-content
    width: 100%
    max-width: 600px
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start
  .btns-wrap
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center
    .rf-comp-btn
      margin: 20px 15px 20px 2px
    @media only screen and (max-width: 1230px)
      flex-direction: column
      align-items: flex-start
      .rf-comp-btn
        margin: 12px 0
    @media only screen and (max-width: 1024px)
      align-items: center
  .frame-wrap
    margin-left: 30px
    width: 100%
    max-width: 442px
    &:only-child
      margin-left: 0
      position: relative
      left: 50%
      transform: translateX(-50%)


@media only screen and (max-width: 1024px)
  .header-5-container
    flex-direction: column
    justify-content: center
    align-items: center
    .header-content
      max-width: unset
      align-items: center
    .frame-wrap
      margin: 60px 0 0 0
      display: flex
      justify-content: center
      &:only-child
        max-width: initial
        margin-top: 0


@media only screen and (max-width: 650px)
  .header-5-container
    .frame-wrap
      max-width: initial

@media only screen and (max-width: 480px)
  .header-5-container
    .frame-wrap
      transform: scale(0.8)
      margin: 20px 0 0 0
      &:only-child
        transform: translateX(-50%) scale(0.8)