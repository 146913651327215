@import '~@website/common/styles/index.sass'
.feature-with-list-container
  display: flex
  justify-content: space-between
  align-items: center
  .texts-content
    margin-bottom: 30px
  .feature-content
    width: 45%
    display: flex
    align-items: flex-start
    flex-direction: column
    justify-content: center
    position: relative
    z-index: 1
    .feature-list-wrap
      display: flex
      flex-direction: column
      list-style: none
      margin: 12px 0 0 15px
      @media only screen and (max-width: 1440px)
        margin-left: 0
      .feature-list-item-wrap
        display: flex
        align-items: center
        .feature-list-item
          padding: 10px
  .feature-image-wrap
    width: 42%
    .feature-image-figure
      text-align: center
      position: relative

@media only screen and (max-width: 992px)
  .feature-with-list-container
    flex-direction: column
    .feature-item
      width: 100%
      margin: 60px 0
    .feature-content
      max-width: 600px
      margin: 0 auto
      text-align: left
      align-items: center

@media only screen and (max-width: 600px)
  .feature-with-list-container
    .feature-item
      margin: 0
