@import '~@website/common/styles/index.sass'
.hint-text
  opacity: 0.5
.light-content
  &:hover
    box-shadow: 0px 0px 0px 1px white
  &:focus
    outline: white solid 1px
.dark-content
  &:hover
    box-shadow: 0px 0px 0px 1px #363F5A
  &:focus
    outline: #363F5A solid 1px
.warning-near
  &:focus
    outline: $accentYellow solid 2px
.warning-max
  &:focus
    outline: $accentRed solid 2px
.sm-word-wrap
  position: relative
  white-space: pre-line
.selectable
  -webkit-user-select: text
  user-select: text
.underline-inherit *
  text-decoration: inherit
