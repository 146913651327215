.feature-14-container
  .row
    height: 450px
    display: flex
    @media only screen and (min-width: 2300px)
      height: 600px
    @media only screen and (max-width: 1100px)
      flex-direction: column !important
      height: auto !important
      & > div
        width: 100% !important
      & > div:nth-child(-n+3)
        height: 500px
      .feature-img, .feature-title-block
        width: 100% !important
    img
      width: 100%
      min-height: 450px
      object-fit: cover
  .feature-title-block
    display: flex
    align-items: center
    justify-content: center
  .row-0
    & > div
      width: calc(100%/3*2)
      flex-grow: 1
      .feature-img
        width: 100%
    .feature-img
      width: calc(100%/3*2)
      flex-grow: 1
    .feature-title-block
      width: calc(100%/3)
      flex-grow: 1
  .row-1
    & > div
      width: calc(100%/3)
      flex-grow: 1
      .feature-img
        width: 100%
    .feature-img
      width: calc(100%/3)
      flex-grow: 1
    .feature-title-block
      width: calc(100%/3)
      flex-grow: 1
  .row-2
    & > div
      width: calc(100%/3*2)
      flex-grow: 1
      .feature-img
        width: 100%
    .feature-img
      width: calc(100%/3*2)
      flex-grow: 1
    .feature-title-block
      width: calc(100%/3)
      flex-grow: 1
