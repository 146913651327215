.feature-15-container
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  .wm-heading
    max-width: 1100px
    margin: 0 auto 50px auto
    .wm-heading-paragraph
      margin: 0 0 40px 0
  .block-content-wrap
    padding: 34px 30px
  .blocks-wrap
    width: 100%
    display: flex
    justify-content: space-between
    margin: 30px 0
    @media only screen and (max-width: 1020px)
      flex-direction: column
    .block-img-wrap
      width: 100%
      font-size: 0
      position: relative
        