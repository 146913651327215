@import '~@website/common/styles/index.sass'
.rf-modal
  background-color: rgba($secondaryDark, 0.8)
  position: fixed
  width: 100%
  height: 100%
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 10002 //change this after ui-lib flyout zIndex change
  display: flex
  justify-content: center
  align-items: center
  .rf-modal-close-button
    position: absolute
    top: 0px
    right: 0px
    padding: 20px
    cursor: pointer
    @media only screen and (max-width: 500px)
      padding: 20px 8px
  .rf-modal-wrap
    width: 95%
    max-width: 550px
    background-color: white
    border-radius: 10px
    padding: 30px
    box-sizing: border-box
    @media only screen and (max-width: 414px)
      padding: 20px
    .rf-modal-Header
      padding-bottom: 1.4rem
    .rf-modal-Footer
      display: flex
      justify-content: flex-end
      flex-direction: column
      margin-top: 20px
      .footer-notification
        padding-top: 10px
        i
          margin-right: 10px
      @media only screen and (max-width: 414px)
        justify-content: center
