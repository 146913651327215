.header-10-container
  position: relative
  min-height: 200px
  display: flex
  flex-direction: column
  .header-content
    width: 100%
    max-width: 600px
    margin: 0 auto
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
  .webpage-wrap
    width: 100%
    max-width: 1000px
    margin: 60px auto 0 auto
    overflow: hidden
    .browser-bar
      display: block
      max-width: initial
    .page-img
      width: 100%
      height: auto
    .code-area
      background-color: #fff
      width: 100%
      height: 250px
      padding: 16px
      box-sizing: border-box
      display: block
      overflow-y: auto